import React from "react";

export const BannerInfo = (props) => {
  return (
    <div id="banner-infos" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>INFOS</h2>
          <h3 className="infoText">MERCI DE <a href="#contact">CONTACTER</a> A L'AVANCE POUR TOUTE DEMANDE DE SEANCES D'ESSAI</h3>
        </div>
      </div>
    </div>
  );
};
