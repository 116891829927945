import React, { useState } from 'react';
import { Image } from './image';

export const Gallery = (props) => {

  console.log(props.data);
  // console.log(firstGalleryData);
  // console.log(secondGalleryData);

  const [showFirstGallery, setShowFirstGallery] = useState(true);

  const toggleGallery = () => {
    setShowFirstGallery(!showFirstGallery);
  };

  return (
    <div id="galerie">
      <div className="container">
        <div className="section-title text-center">
          <h2>{showFirstGallery ? "Première Galerie" : "Deuxième Galerie"}</h2>
        </div>
        <div className="row">
          <div className="gallery-container">
            {props.data ?
              showFirstGallery ? (
                props.data.map((d, i) => (
                  <div key={`${i}`} className="col-sm-6 col-md-4 col-lg-4">
                    <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
                  </div>
                ))
              ) : (
                props.data.map((d, i) => (
                  <div key={`${i}`} className="col-sm-6 col-md-4 col-lg-4">
                    <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
                  </div>
                ))
              )
              : "Chargement ..."
            }
          </div>
        </div>
        {/* <div className="text-center">
          <button onClick={toggleGallery}>Basculer Galerie</button>
        </div> */}
      </div>
    </div>
  );
};

