import React from "react";

export const Tarifs = (props) => {
  return (
    <div id="tarifs">
      <div className="container-fluid">
        <div className="section-title text-center">
          <h2>Tarifs</h2>
        </div>
        <div className="row">
          {/* <div className="tarif-container"> */}
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-sm-6 col-md-4 col-lg-4">
                <img src={d.img} alt="..." className="img-responsive" />
              </div>
            ))
            : "Chargement..."
          }
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};
